import { isObject } from 'pl-games-kit';
import { SOCKET_EVENTS, SOCKET_STATES } from '../constants/apiConstants';
import { constructQueryParams } from '../helpers';

class WebsocketClient extends EventTarget {
    constructor(url = '', options = {}) {
        super();

        const {
            query,
        } = options;

        this.events = {};
        this.timerId = null;
        this.messageQueue = new Set();
        this.attemptToReconnect = false;
        this.reconnectTimeout = 100;

        this.onVisibilityChange = () => {
            if (document.visibilityState === 'visible' && [2, 3].includes(this.client.readyState)) {
                this.client.close();
                this.connect();
            }
        };

        document.addEventListener('visibilitychange', this.onVisibilityChange);

        this.connect = () => {
            this.timerId && clearTimeout(this.timerId);

            this.client = new WebSocket(query ? `${url}/?${constructQueryParams(query)}` : url);

            this.client.onopen = () => {
                this.#listenersExecutor(SOCKET_STATES.connect);
                console.log('Websocket connected');

                // TODO should be configurable for specific events
                this.messageQueue.forEach((data = '') => {
                    this.client.send(data);
                    // deleting already sent messages from the queue
                    this.messageQueue.delete(data);
                });

                this.client.onmessage = (messageEvent = {}) => {
                    const message = JSON.parse(messageEvent.data);
                    const [event, data, options] = message;

                    if (isObject(event)) {
                        for (const key in event) {
                            this.#listenersExecutor(key, event[key], options);
                        }
                    } else {
                        this.#listenersExecutor(event, data, options);
                    }
                };
            };

            this.client.onerror = () => {
                this.attemptToReconnect = true;
                this.client.close();
            };

            this.client.onclose = (e) => {
                document.removeEventListener('visibilitychange', this.onVisibilityChange);

                if (this.attemptToReconnect) {
                    this.reconnectTimeout += this.reconnectTimeout;
                    const time = Math.min(this.reconnectTimeout, 5000);

                    console.log(`Socket reconnection is expected in ${time}ms`, e.reason);

                    this.#listenersExecutor(SOCKET_STATES.reconnecting);
                    this.timerId = setTimeout(() => {
                        this.connect();
                    }, time);
                } else {
                    if (e.reason === SOCKET_EVENTS.BAN || e.code === 1005) {
                        this.#listenersExecutor(SOCKET_EVENTS.BAN);
                    }
                    this.#listenersExecutor(SOCKET_STATES.disconnect);
                    console.log('Socket connection is closed');
                }
            };
        };

        this.connect();
    }

    #broadcastEvent (eventName, data = {}, options = {}) {
        const event = new CustomEvent(eventName, { detail: { ...(Array.isArray(data) ? { data } : data), ...options } });
        this.dispatchEvent(event);
    }

    disconnect () {
        this.client.close();
    }

    eventsList (name) {
        if (!this.events[name]) {
            this.events[name] = new Set();
        }

        return this.events[name];
    }

    #listenersExecutor (event, data, options) {
        // executing attached event listeners for current event
        this.#broadcastEvent(event, data, options);
        this.eventsList(event).forEach((fn) => {
            fn(data, options);
        });
    }

    on (eventName, fn = () => {}) {
        this.eventsList(eventName).add(fn);
    }

    emit (eventName, data, options) {
        const message = JSON.stringify([eventName, { ...data }, { ...options }]);

        // if socket is not fully connected the message is set in message queue to be executed after connection is established
        if (this.client.readyState === 1) { // 1 === OPEN
            this.client.send(message);
        } else {
            this.messageQueue.add(message);
        }
    }
}

export default WebsocketClient;
