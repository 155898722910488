export const SOCKET_EVENTS = {
    ENGINE_STATE: 'engineState',
    RESTORED_BET: 'restoredBet',
    GAME_STATE: 'gameState',
    DO_BET: 'doBet',
    CANCEL_BET: 'cancelBet',
    PROFILE: 'profile',
    UPDATE_PROFILE: 'updateProfile',
    PLAYERS: 'players',
    MOVE: 'move',
    BAN: 'ban',
    BETS: 'bets',
    TOP_WINS: 'topWins',
    MY_BETS: 'myBets',
    HISTORY: 'gameHistory',
    WIN_OUTCOME: 'winOutcome',
    PF: 'pf',
    SERVER_SEED: 'serverSeed',
    CHAT_MESSAGE: 'chat:message',
    CHAT_HISTORY: 'chat:history',
    CHAT_INIT: 'chat:init',
    UPDATE_BALANCE: 'updateBalance',
};

export const SOCKET_STATES = {
    connect: 'connect',
    connect_error: 'connect_error',
    connect_timeout: 'connect_timeout',
    error: 'error',
    disconnect: 'disconnect',
    reconnect: 'reconnect',
    reconnect_attempt: 'reconnect_attempt',
    reconnecting: 'reconnecting',
    reconnect_error: 'reconnect_error',
    reconnect_failed: 'reconnect_failed'
};
