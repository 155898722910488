import {
    tap,
    scan,
    share,
    pluck,
    merge,
    filter,
    fromEvent,
    shareReplay,
    combineLatest,
    BehaviorSubject,
} from 'rxjs';
import { getUrlParameterByName, BaseService, isDemo, gaSet, randomSeedGenerator } from 'pl-games-kit';
import { map } from 'rxjs/operators';

import { dataAccumulator } from '../helpers';

import mainConfig from '../configs/mainConfig';

import { SOCKET_EVENTS, SOCKET_STATES } from '../constants/apiConstants';
import { ENGINE_PHASES } from '../constants/gameConstants';

import WebsocketClient from './websocketClient';

export class DataService extends BaseService {
    constructor() {
        super();

        const demoMode = mainConfig.game.demoMode;
        const token = getUrlParameterByName('token') || (demoMode.enable && 'demo') || '123';
        const partner_id = getUrlParameterByName('partner_id') || getUrlParameterByName('partnerid') || 1;

        this.socket = new WebsocketClient(`${mainConfig.connection.socket.url}/${mainConfig.connection.socket.path}`, {
            query: {
                token,
                partner_id,
            },
        });

        this.currentRid = 0;
        this.connectorsStore = {};

        this._serviceState = new BehaviorSubject(null);

        this._profile = new BehaviorSubject({});
        this._playerState = new BehaviorSubject({});
        this._profileBalance = new BehaviorSubject({});
        this._nextRoundSeeds = new BehaviorSubject({});

        this._engineState = fromEvent(this.socket, SOCKET_EVENTS.ENGINE_STATE)
            .pipe(
                pluck('detail'),
                tap(value => {
                    const currentRound = this._nextRoundSeeds.getValue().round;

                    if (!currentRound || (value.round !== currentRound && value.phase === ENGINE_PHASES.STARTING)) {
                        this._nextRoundSeeds.next({
                            round: value.round,
                            seed: randomSeedGenerator(),
                            manualSeed: '',
                            isManual: false,
                        });
                    }
                }),
                shareReplay(1)
            );

        this._restoredBet = fromEvent(this.socket, SOCKET_EVENTS.RESTORED_BET)
            .pipe(
                pluck('detail'),
                shareReplay(1)
            );

        // merging current user with other players so that the user can always be on the playground
        // this list is meant to be used to depict players on the playground only
        this._players = combineLatest(
            [
                this._profile,
                fromEvent(this.socket, SOCKET_EVENTS.PLAYERS)
                    .pipe(
                        pluck('detail'),
                        tap((playersList) => {
                            const profile = this._profile.getValue();
                            const player = playersList[profile.userIdHash];
                            this._playerState.next(player ? { ...player, id: profile.userIdHash } : {});
                        }),
                        scan(dataAccumulator, {}),
                    )
            ]
        ).pipe(
            map(([userProfile, realPlayers]) => {
                return new Map([
                    ...(userProfile.userIdHash ? new Map([
                        [
                            userProfile.userIdHash, {
                                isAlive: true,
                                isWon: false,
                                moveDuration: 0,
                                startMovingTime: null,
                                avatar: userProfile.avatar || {},
                            }
                        ]
                    ]) : new Map()),
                    ...(new Map(Object.entries(realPlayers).map((val) => {
                        const [id, data] = val;
                        const { avatar, avatarBackground, ...rest } = data;
                        return [id, {
                            ...rest,
                            avatar: {
                                type: avatar ?? 2,
                                color: avatarBackground ?? 0,
                            },
                        }];
                    })))
                ]);
            }),
            shareReplay(1)
        );

        this._gameState = fromEvent(this.socket, SOCKET_EVENTS.GAME_STATE)
            .pipe(
                pluck('detail'),
                shareReplay(1)
            );

        this._sharedGameProfile = fromEvent(this.socket, SOCKET_EVENTS.PROFILE).pipe(
            pluck('detail'),
            tap(val => {
                gaSet('userId', val.id);
            }),
            tap(val => {
                this._profile.next({
                    ...val,
                    avatar: {
                        type: val.avatar ?? 2,
                        color: val.avatarBackground ?? 0,
                    }, ...(isDemo && demoMode.enable && !demoMode.ignoreDemoCurrency ? demoMode.demoCurrency : {})
                });
            }),
            tap(val => {
                this._currency = {
                    ...val.currency,
                    ...(isDemo && demoMode.enable && !demoMode.ignoreDemoCurrency ? demoMode.demoCurrency : {}).currency_rate,
                    balance: val.totalBalance,
                };

                this._profileBalance.next({
                    balance: val.totalBalance, currency: val.currency,
                    ...(isDemo && demoMode.enable && !demoMode.ignoreDemoCurrency ? demoMode.demoCurrency : {}).currency_rate,
                });
            }),
        );

        this._rounds = merge(
            fromEvent(this.socket, SOCKET_EVENTS.BETS).pipe(
                pluck('detail')
            ),
            this._engineState.pipe(
                filter(val => val.phase === ENGINE_PHASES.STARTING),
                map(() => [])
            )
        ).pipe(
            scan(dataAccumulator, {}),
            map(val => Object.keys(val).map(key => val[key])),
            map(val => val.sort((a, b) => b.amount - a.amount)),
            shareReplay(1),
        );

        this._winOutcome = fromEvent(this.socket, SOCKET_EVENTS.WIN_OUTCOME)
            .pipe(
                pluck('detail'),
                shareReplay(1),
            );

        this._balanceUpdate = fromEvent(this.socket, SOCKET_EVENTS.UPDATE_BALANCE)
            .pipe(
                pluck('detail'),
                map(val => ({ balance: val.newBalance, currency: val.currency })),
                tap(val => {
                    this._profileBalance.next(val);
                }),
                shareReplay(1),
            );

        this.socket.on(SOCKET_STATES.connect, () => {
            this.subscription = this._sharedGameProfile.subscribe();
            this.subscription.add(this._gameState.subscribe());
            this.subscription.add(this._players.subscribe());
            this.subscription.add(this._serviceState.subscribe());
            this.subscription.add(this._engineState.subscribe());
            this.subscription.add(this._restoredBet.subscribe());
            this.subscription.add(this._rounds.subscribe());
            this.subscription.add(this._winOutcome.subscribe());
            this.subscription.add(this._balanceUpdate.subscribe());
        });

        this.socket.on(SOCKET_STATES.disconnect, () => {
            this.subscription && this.subscription.unsubscribe();
        });

        this.socket.on(SOCKET_EVENTS.BAN, () => {
            this.socket.disconnect();
        });

        Object.keys(SOCKET_STATES).forEach(key => {
            this.socket.on(SOCKET_STATES[key], (val) => {
                this._serviceState.next(SOCKET_STATES[key]);
            });
        });
    }

    speedometer() {
        let start = new Date();
        const handlePing = () => {
            start = new Date();
        };
        const handlePong = () => {
            const delay = Date.now() - start;
            this.displaySlowConnectionWarning(delay);
        };
        this.socket.on('ping', handlePing);
        this.socket.on('pong', handlePong);
    }

    nextRid() {
        return this.currentRid++;
    }

    getConnector(eventName) {
        const connector = this.connectorsStore[eventName];
        if (connector) {
            return connector;
        }
        return this.connectorsStore[eventName] = fromEvent(this.socket, eventName).pipe(
            share()
        );
    }

    sendEvent(eventName, data = {}, withRid = true, cb = () => {}) {
        const rid = withRid && this.nextRid();
        cb(rid);
        this.socket.emit(eventName, { ...(data ? data : {}) }, { ...(withRid ? { rid } : {}) });
        return this.getConnector(eventName).pipe(
            pluck('detail'),
            withRid && filter(val => val?.rid === rid),
        );
    }

    getEvent = (event, data = {}) => {
        this.socket.emit(event, data);
    };

    get socketState() {
        return this._serviceState;
    }

    get profile() {
        return this._profile;
    }

    get gameState() {
        return this._gameState;
    }

    get restoredBet() {
        return this._restoredBet;
    }

    get engineState() {
        return this._engineState;
    }

    get playerState() {
        return this._playerState;
    }

    get players() {
        return this._players;
    }

    get nextRoundSeeds() {
        return this._nextRoundSeeds;
    }

    setNextRoundSeeds(newSeeds) {
        const currentSeeds = this._nextRoundSeeds.getValue();
        this._nextRoundSeeds.next({ ...currentSeeds, ...newSeeds, seed: currentSeeds.seed });
    }

    get currency() {
        return this._currency;
    }

    get rounds() {
        return this._rounds;
    }

    get winOutcome() {
        return this._winOutcome;
    }

    get profileBalance() {
        return this._profileBalance;
    }
}
