export const ENGINE_PHASES = {
    STARTING: 'starting',
    BETTING: 'betting',
    RUNNING: 'running',
    POST_BETTING: 'postBetting',
    ENDING: 'ending',
};

export const PROXY_KEYS = {
    PLAYERS_PROGRESS: 'playersProgress',
};

export const CASINO_ERRORS = {
    4: 'Wrong Bet Amount',
    7: 'Wrong Game ID',
    8: 'Authentication Failed',
    21: 'Insufficient funds',
    29: 'Player Is Blocked',
    34: 'Wrong Currency',
    63: 'Wrong Win Amount',
    84: 'Game is Blocked',
    104: 'Transaction is already exist',
    105: 'Transaction is already canceled',
    106: 'Token Expired',
    107: 'Transaction not found',
    114: 'Token not Found',
    130: 'General Error',
    200: 'Incorrect Parameters Passed',
    '-1': 'Insufficient balance',
    '-2': 'Max stake exceed',
    '-3': 'Bet canceled for delay',
    '-4': 'Invalid bet amount applied',
    default: 'General error'
};
