import { getUrlParameterByName, mergeDeep } from 'pl-games-kit';
import connectionService from '../services/connectionService';
import { currencyLimits, unknownCurrency } from './currencyConfig';
import partnerConfig from './partnerConfig';

const appMode = process.env.REACT_APP_MODE;

export default (() => mergeDeep({
    connection: {
        socket: {
            url: appMode === 'production'
                ? `wss://${window.location.hostname || 'redgreen.betcoapps.com'}`
                : process.env.REACT_APP_MODE === 'staging'
                    ? 'wss://redgreen-stage.betcoapps.com'
                    : 'ws://redgreen.betconstruct.int',

            chatUrl: appMode === 'development'
                ? 'wss://bet-gaming-chat-dev.betcoapps.com'
                : appMode === 'staging'
                    ? 'ws://bet-gaming-chat-stage.betcoapps.com'
                    : 'wss://bet-gaming-chat.bcapps.org',
            path: 'websocket'
        },
        slackWebHook: {
            url: 'https://hooks.slack.com/services/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',
            channel: 'pl_games_errors_handler',
        },
    },
    analytics: {
        trackingId: 'UA-140469233-4'
    },
    game: {
        disableAutoBet: false,
        distance: 979,
        winDuration: 8000,
        breakpoints: {
            mobile: 767,
            tablet: 960,
        },
        demoMode: {
            enable: true,
            viewOnly: false,
            ignoreDemoCurrency: false, // works for view only mode
            canSwitchToReal: true,
            demoCurrency: {
                currency: 'FUN',
                initialBalance: 1000,
                currency_rate: { id: 'FUN', name: '', symbol: '', rate: '1', version: 1063 },
            }
        },
        defaultLimits: {
            currencyPrecisionPoint: 2,
            bet: {
                minStake: 20,
                maxStake: 60000,
                stakeStep: 1,
                maxWin: 600000,
            },
        },
        get limits() {
            let currency = connectionService?.currency;
            return {
                ...this.defaultLimits,
                ...(currency?.id
                    ? (currencyLimits[currency.id] || unknownCurrency(currency || {}))
                    : {})
            };
        }
    }
}, partnerConfig[getUrlParameterByName('partnerId') || getUrlParameterByName('partnerid')] || {}))();
