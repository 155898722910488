import { Translate } from 'pl-games-kit';

export const tabsProps = {
    appearance: [
        'line',
        'button',
        'extra',
    ],
};

export const modalProps = {
    type: [
        'generateSeed',
        'howToPlay',
        'whatIsPF',
        'roundInfo',
        'profile',
        'avatarChooser',
    ],
};

export const gameResultGraphProps = {
    mode: [
        'colors',
        'payout',
    ],
};

export const logoProps = {
    mode: [
        'wide',
        'compact',
    ],
};

export const popoverProps = {
    appearance: [
        'light',
        'dark',
    ],
    placement: [
        'auto',
        'top',
        'top-start',
        'top-end',
        'bottom',
        'bottom-start',
        'bottom-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
        'auto-start',
        'auto-end',
    ],
};

export const buttonProps = {
    size: [
        'small',
        'medium',
        'big',
        'extra-big',
    ],
    color: [
        'confirm',
        'danger',
        'accent',
    ],
    flexibility: [
        'full',
        'fit',
    ],
};

export const avatarProps = {
    appearance: [
        'box',
        'clean',
        'platform',
    ],
    color: [
        0,
        1,
        2,
        3,
    ],
    type: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
    ],
};

export const outcomeBoxProps = {
    mode: [
        'win',
        'loose',
    ],
};

export const toastProps = {
    type: {
        success: {
            title: <Translate>Successfully done</Translate>,
        },
        danger: {
            title: <Translate>Something went wrong</Translate>,
        },
        info: {
            title: <Translate>Information</Translate>,
        },
        warning: {
            title: <Translate>Something is wrong</Translate>,
        },
    },
    default: {
        type: 'success',
        container: 'top-right',
        animationIn: ['reveal'],
        animationOut: ['hide'],
        width: 336,
        dismiss: {
            click: true,
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            showIcon: true,
        },
    },
};

export const messageBubbleProps = {
    appearance: [
        'author',
        'user',
    ],
    contents: [
        'image',
        'emoji',
        'text',
    ],
};
