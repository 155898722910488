import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

import { ReactComponent as Cloud1 } from './media/cloud-1.svg';
import { ReactComponent as Cloud2 } from './media/cloud-2.svg';

const StaticMessage = props => {
    const {
        extra,
        title,
        description,
    } = props;

    return (
        <div className="static-message-holder">
            <div className="static-extra-decor-holder">
                {extra && (
                    <div className="static-extra">
                        <ul className="se-traffic-lights">
                            <li/>
                            <li/>
                        </ul>
                    </div>
                )}
                <ul className="static-decor">
                    <li>
                        <small className="t-1"/>
                    </li>
                    <li>
                        <small className="t-2"/>
                    </li>
                    <li>
                        <small className="t-3"/>
                    </li>
                    <li>
                        <Cloud1/>
                    </li>
                    <li>
                        <Cloud2/>
                    </li>
                </ul>
            </div>
            <div className="sm-title">
                {title}
            </div>
            <div className="sm-description">
                {description}
            </div>
        </div>
    );
};

StaticMessage.propTypes = {
    extra: PropTypes.bool,
    title: PropTypes.any,
    description: PropTypes.any,
};

export default StaticMessage;
