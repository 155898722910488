import { getUrlParameterByName, mergeDeep, sendMessageToSlack } from 'pl-games-kit';

export const unknownCurrency = ({ rate }) => {
    if (rate) {
        const minStakeAmount = parseInt(0.1 / rate);
        try {
            Math.pow(10, parseInt(minStakeAmount).toString().length - 1);
        } catch (e) {
            sendMessageToSlack('toString bug currencyConfig', { minStakeAmount });
        }
        let multiplier = Math.pow(10, parseInt(minStakeAmount).toString().length - 1);
        const realMultiplier = multiplier > minStakeAmount ? multiplier : 2 * multiplier;
        return {
            currencyPrecisionPoint: 2,
            bet: {
                presetStakes: [
                    realMultiplier,
                    2 * realMultiplier,
                    5 * realMultiplier,
                    10 * realMultiplier,
                    25 * realMultiplier,
                ],
                minStake: Number((0.1 / rate).toFixed(2)),
                maxStake: Number((123 / rate).toFixed(2)),
                stakeStep: 0.1 * multiplier,
                maxWin: Number((12330 / rate).toFixed(2))
            }
        };
    }
    return {};
};
const partnerCurrencyLimits = {};

export const currencyLimits = (() => {
    const partnerId = getUrlParameterByName('partnerId') || getUrlParameterByName('partnerid');
    return mergeDeep(
        {
            AMD: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [200, 400, 1000, 2000, 5000],
                    minStake: 20,
                    maxStake: 60000,
                    stakeStep: 1,
                    maxWin: 6000000
                },
            },
            USD: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [0.1, 0.2, 0.5, 1, 2.5],
                    minStake: 0.1,
                    maxStake: 100,
                    stakeStep: 0.1,
                    maxWin: 10000,
                },
            },
            EUR: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [0.1, 0.2, 0.5, 1, 2.5],
                    minStake: 0.1,
                    maxStake: 100,
                    stakeStep: 0.1,
                    maxWin: 10000
                },
            },
            BYN: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [0.25, 0.5, 1, 1.5, 2],
                    minStake: 0.25,
                    maxStake: 250,
                    stakeStep: 0.25,
                    maxWin: 25000
                },
            },
            CAD: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [0.1, 0.2, 0.5, 1, 2.5],
                    minStake: 0.1,
                    maxStake: 150,
                    stakeStep: 0.1,
                    maxWin: 15000
                },
            },
            RUB: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [25, 50, 125, 250, 625],
                    minStake: 2.5,
                    maxStake: 8000,
                    stakeStep: 1,
                    maxWin: 800000
                },
            },
            INR: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [20, 50, 100, 250, 500],
                    minStake: 5,
                    maxStake: 8000,
                    stakeStep: 1,
                    maxWin: 800000
                },
            },
            GEL: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [1, 2, 5, 10, 25],
                    minStake: 0.10,
                    maxStake: 300,
                    stakeStep: 0.01,
                    maxWin: 30000
                },
            },
            SEK: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [5, 10, 25, 50, 125],
                    minStake: 0.50,
                    maxStake: 1000,
                    stakeStep: 0.01,
                    maxWin: 100000

                },
            },
            UAH: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [1, 10, 20, 50, 100, 250],
                    minStake: 1,
                    maxStake: 3000,
                    stakeStep: 1,
                    maxWin: 300000
                },
            },
            PLN: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [15, 30, 75, 150, 375],
                    minStake: 0.15,
                    maxStake: 450,
                    stakeStep: 0.01,
                    maxWin: 45000
                },
            },
            BRL: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [15, 30, 75, 150, 375],
                    minStake: 0.15,
                    maxStake: 450,
                    stakeStep: 0.01,
                    maxWin: 45000
                },
            },
            MXN: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [10, 20, 50, 100, 250],
                    minStake: 1,
                    maxStake: 2000,
                    stakeStep: 1,
                    maxWin: 200000

                },
            },

            KZT: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [150, 300, 750, 1500, 3750],
                    minStake: 15,
                    maxStake: 45000,
                    stakeStep: 1,
                    maxWin: 4500000
                },
            },
            CNY: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [2.5, 5, 12.5, 25, 62.5],
                    minStake: 0.25,
                    maxStake: 850,
                    stakeStep: 0.01,
                    maxWin: 85000
                },
            },
            PEN: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [1, 2, 5, 10, 25],
                    minStake: 0.10,
                    maxStake: 300,
                    stakeStep: 0.01,
                    maxWin: 30000
                },
            },
            KRW: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [500, 1000, 2500, 5000, 12500],
                    minStake: 50,
                    maxStake: 100000,
                    stakeStep: 0.01,
                    maxWin: 10000000
                },
            },
            MDL: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [10, 20, 50, 100, 250],
                    minStake: 1,
                    maxStake: 2000,
                    stakeStep: 0.01,
                    maxWin: 200000
                },
            },
            TRY: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [2.5, 5, 12.5, 25, 62.5],
                    minStake: 0.25,
                    maxStake: 750,
                    stakeStep: 0.01,
                    maxWin: 75000
                },
            },
            IRR: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [50000, 100000, 250000, 1000000, 5000000],
                    minStake: 10000,
                    maxStake: 15000000,
                    stakeStep: 10000,
                    maxWin: 1500000000
                },
            },
            TOM: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [5000, 10000, 25000, 100000, 500000],
                    minStake: 1500,
                    maxStake: 1500000,
                    stakeStep: 1000,
                    maxWin: 150000000
                },
            },
            IQD: {
                currencyPrecisionPoint: 0,
                bet: {
                    presetStakes: [500, 1000, 5000, 10000, 50000, 100000],
                    minStake: 500,
                    maxStake: 100000,
                    stakeStep: 100,
                    maxWin: 12000000,
                    withoutAllIn: true
                },
            },
            IRT: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [5, 10, 50, 100, 500],
                    minStake: 0.15,
                    maxStake: 1500,
                    stakeStep: 1,
                    maxWin: 150000,
                },
            },
            IRB: {
                currencyPrecisionPoint: 0,
                useCurrencySymbol: true,
                bet: {
                    presetStakes: [5000, 10000, 25000, 100000, 500000],
                    minStake: 2000,
                    maxStake: 1500000,
                    stakeStep: 1000,
                    maxWin: 150000000
                },
            },
            FUN: {
                currencyPrecisionPoint: 2,
                bet: {
                    presetStakes: [0.1, 0.2, 0.5, 1, 2.5],
                    minStake: 0.1,
                    maxStake: 1000,
                    stakeStep: 0.1,
                    maxWin: 10000,
                },
            },
        }, (partnerCurrencyLimits[partnerId] || {}));
})();
