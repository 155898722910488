import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'pl-games-kit';

import App from './App';
import mainConfig from './configs/mainConfig';

import reportWebVitals from './reportWebVitals';

import './assets/styles/index.scss';

// disable warnings and errors in production build
if (process.env.REACT_APP_MODE === 'production' || process.env.REACT_APP_MODE === 'production-cloud') {
    console.log = () => {
    };
    console.warn = () => {
    };
    console.error = () => {
    };
}

ReactDOM.render(
    <React.StrictMode>
        {process.env.REACT_APP_MODE === 'production' ? (
            <ErrorBoundary
                channel={mainConfig.connection.slackWebHook.channel}
                webHookUrl={mainConfig.connection.slackWebHook.url}
            >
                <App/>
            </ErrorBoundary>
        ) : (
            <App/>
        )}
    </React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
