import React from 'react';
import { createPortal } from 'react-dom';
import { Translate } from 'pl-games-kit';

import Scrollbar from '../scrollbar';
import StaticMessage from '../staticMessage';

import './index.scss';

const YouAreAway = () => createPortal(
    <div className="you-are-away-holder">
        <Scrollbar>
            <div className="you-are-away">
                <StaticMessage
                    extra
                    title={
                        <Translate>You are away</Translate>
                    }
                    description={
                        <Translate>Your session was interrupted due to inactivity or login from another device</Translate>
                    }
                />
            </div>
        </Scrollbar>
    </div>,
    document.body
);

export default YouAreAway;
