import { BehaviorSubject, combineLatest, filter, of, pluck } from 'rxjs';
import { map } from 'rxjs/operators';
import { SOCKET_EVENTS } from '../constants/apiConstants';
import { ENGINE_PHASES } from '../constants/gameConstants';

import { DataService } from './dataService';

export class DemoService extends DataService {
    constructor() {
        super();

        this._betRid = null;
        this._currentBet = null;
        this._demoBets = new BehaviorSubject([]);

        super.getConnector(SOCKET_EVENTS.DO_BET).pipe(
            pluck('detail'),
            filter(val => val?.rid === this._betRid),
        ).subscribe((val) => {
            this._currentBet = val;
        });

        combineLatest([this._rounds, this._engineState])
            .subscribe(([val, engineState]) => {
                const bet = val.find(el => el.id === this._currentBet?.id);
                const demoBets = this._demoBets.getValue();
                const isBetting = engineState.phase === ENGINE_PHASES.BETTING;

                if (!bet) {
                    isBetting && this._demoBets.next(demoBets.filter(el => el.id !== this._currentBet?.id));
                    this._currentBet = null;
                } else {
                    const demoBet = demoBets.findIndex(bet => bet.id === this._currentBet.id);

                    if (demoBet !== -1) {
                        this._demoBets.next(
                            [
                                ...demoBets.slice(0, demoBet),
                                bet,
                                ...demoBets.slice(demoBet + 1),
                            ]
                        );
                    } else {
                        this._demoBets.next([bet, ...demoBets]);
                    }
                }
            });
    }

    sendEvent(eventName, data, withRid = true) {
        const cb = (rid) => {
            if (eventName === SOCKET_EVENTS.DO_BET) {
                this._betRid = rid;
            }
        };

        return super.sendEvent(eventName, data, withRid, cb);
    }


    getConnector(eventName) {
        if (eventName === SOCKET_EVENTS.MY_BETS) {
            return of(this._demoBets.getValue())
                .pipe(
                    map(val => ({ detail: { data: val } }))
                );
        }

        return super.getConnector(eventName);
    }
}
