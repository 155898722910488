import React, { lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';

import { initApp, useWindowResize } from 'pl-games-kit';

import YouAreAway from './components/youAreAway';

import connectionService from './services/connectionService';
import mainConfig from './configs/mainConfig';

const Main = lazy(() => import('./layout/main'));
const Calculator = lazy(() => import('./components/calculator'));

const App = props => {
    const {
        appReady,
        isCalcMode,
    } = props;

    const {
        height,
        initialHeight,
    } = useWindowResize();

    useEffect(() => {
        document.documentElement.style.setProperty('--vh', height + 'px');
        document.documentElement.style.setProperty('--init-vh', `${initialHeight}px`);
    }, [height, initialHeight]);

    useEffect(() => {
        document.body.classList[isCalcMode ? 'add' : 'remove']('np');
    }, [isCalcMode]);

    return (
        <Suspense fallback={null}>
            {isCalcMode ? (
                <Calculator/>
            ) : (
                <Main appReady={appReady}/>
            )}
        </Suspense>
    );
};

App.propTypes = {
    appReady: PropTypes.bool,
    isCalcMode: PropTypes.bool,
};
export default initApp(App, YouAreAway, mainConfig, connectionService);
