import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { useElementResize } from 'pl-games-kit';
import PropTypes from 'prop-types';

import './index.scss';

const Scrollbar = props => {
    const {
        children,
        suppressScrollX,
        suppressScrollY,
        externalElement,
    } = props;

    const ref = useRef(null);
    const [ps, setPs] = useState(null);
    const { width, height } = useElementResize(ref);

    useEffect(() => {
        if (externalElement) {
            ref.current = document.querySelector(`.${externalElement}`);
        }
    }, [externalElement]);

    useEffect(() => {
        setPs(new PerfectScrollbar(ref.current, {
            suppressScrollX,
            suppressScrollY,
        }));
    }, []); // eslint-disable-line

    useEffect(() => {
        !!ps && ps.update();
    }, [ps, width, height, children]);

    useEffect(() => {
        if ('ontouchstart' in document.documentElement) {
            !!ps && ps.destroy();
            ref.current.classList.add('ps', 'touch');
        }

        return () => {
            !!ps && ps.destroy();
        };
    }, [ps]);

    return externalElement ? children : (
        <div ref={ref}>
            {children}
        </div>
    );
};

Scrollbar.defaultProps = {
    suppressScrollX: true,
};

Scrollbar.propTypes = {
    children: PropTypes.any,
    suppressScrollX: PropTypes.bool,
    suppressScrollY: PropTypes.bool,
    externalElement: PropTypes.string,
};

export default Scrollbar;
