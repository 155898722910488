import { conditionalSpread, getUrlParameterByName, isMobile, toFixed } from 'pl-games-kit';
import { Store } from 'react-notifications-component';
import mainConfig from '../configs/mainConfig';
import { toastProps } from '../constants/props';

export const addToast = options => {
    const toast = {
        ...toastProps.default,
        ...options,
        ...conditionalSpread({
            dismiss: {
                ...toastProps.default.dismiss,
                ...options.dismiss,
            }
        }, options?.dismiss),
    };
    return Store.addNotification({
        ...toast,
        ...conditionalSpread({
            title: toastProps.type[toast.type].title,
        }, !toast.title),
        width: 332,
        message: toast?.message || toastProps.type[toast.type].title,
    });
};

export const constructQueryParams = (params = {}) => {
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
};

export const moveTimeToDistance = (time) => (time * mainConfig.game.distance) / mainConfig.game.winDuration;

export const positionToPercentage = num => num ? `${Math.floor(num * 100 / mainConfig.game.distance)}%` : '0%';

export const getAlivePlayersCount = (currentPlayerState = {}, players) => {
    const playersList = Array.from(players.values());
    const count = playersList.reduce((acc, curr) => acc + Number(curr.isAlive), 0);

    return Math.max(Number(Object.keys(currentPlayerState).length ? count : count - 1), 0);
};

export const currentOdd = (time) => {
    const x = (time < 7.2 ? 7.2 : time) / 0.6;
    return 5 + Math.pow(0.8, (x - 36.74));
};

export const dataAccumulator = (acc, val = {}) => {
    if (Object.keys(val).length) {
        Object.keys(val).forEach(id => {
            !val[id] && delete val[id] && delete acc[id];
        });
        return { ...acc, ...val };
    } else {
        return {};
    }
};

export const formatTime = (timestamp) => {
    const date = new Date(Number.isNaN(Number(timestamp)) ? timestamp : Number(timestamp));

    return `${date.getHours()}:${`${date.getMinutes()}`.padStart(2, '0')}`;
};

export const calcLightTime = (data, index) => {
    let start = 0;
    let end = 0;

    for (let i = 0; i < data.length && i <= index; i++) {
        const light = data[i];
        start += i !== index ? light.duration : 0;
        end += light.duration;
    }

    return { start: toFixed(start, 2), end: toFixed(end, 2) };
};

export const round = function (num, count = 2) {
    return Number(Math.round(+(num + 'e' + count)) + 'e-' + count);
};

export const getGameResult = (lights = []) => {
    const GAME_TOTAL_DURATION = 36;

    const graph = [];
    let idx = 0;
    let prevEnd = 0;

    for (const { light, duration } of lights) {
        prevEnd = graph[idx - 1]?.end ?? GAME_TOTAL_DURATION;

        graph.push({ light, start: prevEnd, end: round(prevEnd - duration, 2) });
        idx++;
    }
    return graph.map(({ light, start, end }) => ({ light, range: `${start} - ${isNaN(end) ? 0 : end}` }));
};

export function* getSequenceFromServerSeedHash(hash, seedLength = SEED_LENGTH, stepCount = STEP_COUNT) {
    let idx = 0;

    while (idx + seedLength - 1 < hash.length) {
        yield parseInt(hash.slice(idx, idx + seedLength), 16);
        idx += stepCount;
    }
}

const SEED_LENGTH = 6;
const STEP_COUNT = 1;

export const calculateLights = (serverSeedHash) => {
    const durations = [[0.6, 1.2, 1.8], [1.2, 1.8, 2.4]];
    const lightsExpectedValues = [0.1, 0.15, 0.2, 0.4, 0.6, 0.8];

    const durationSeedGen = getSequenceFromServerSeedHash(serverSeedHash);
    const lightSeedGen = getSequenceFromServerSeedHash(serverSeedHash.split('').reverse().join(''));

    const choseDuration = (light, rnd) => durations[light][rnd % 3];
    const choseLight = (currentSec, rnd) => {
        const myRnd = rnd / parseInt(new Array(SEED_LENGTH).fill('F').join(''), 16);
        return myRnd > lightsExpectedValues[Math.floor(currentSec / 6)] ? 0 : 1;
    };
    const result = [];
    let tempResult = {};
    let sec = 0;
    while (sec < 36) {
        const light = choseLight(sec, lightSeedGen.next().value);
        const duration = choseDuration(light, durationSeedGen.next().value);
        if (tempResult.light === undefined || tempResult.light === light) {
            tempResult = { light, duration: (tempResult.duration || 0) + duration };
        } else {
            result.push(tempResult);
            tempResult = { light, duration };
        }
        sec += duration;
        if (sec >= 36) {
            tempResult.duration = tempResult.duration - (sec - 36);
            result.push(tempResult);
        }
    }
    return result;
};

export const insideWebMobile = () => {
    const [subdomain] = (window.location.ancestorOrigins?.[0])?.split('.') || [];
    const url = subdomain && new URL(subdomain);
    return !((url?.host || window.location.host) === 'games' || (url?.host || window.location.host)?.includes('localhost'));
};

export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export const openParentPageCustom = (tab = 'login') => {
    if (window.self !== window.top && insideWebMobile()) {
        window.top.top.postMessage({ action: 'openSlider', tab }, '*');
    } else if (isMobile()) {
        const [exitUrl, partnerId] = ['exitUrl', 'partnerid'].reduce((acc, curr) => ([...acc, getUrlParameterByName(curr)]), []);
        const gameId = '5000013';
        const { origin } = new URL(exitUrl);
        const redirectUrl = encodeURIComponent(`${origin}/authorization.php?partnerId=${partnerId}&gameId=${gameId}&openType=real&isMobile=true&language={language}&token={token}&currency={currency}&exitURL=${exitUrl}`);

        window.location.replace(`//${getUrlParameterByName('Id')}/#/?${tab === 'login' ? `sign-in&redirectUrl=${redirectUrl}&` : ''}action=${tab}`);
    } else {
        const exitUrl = getUrlParameterByName('exitUrl');
        exitUrl && (window.location.href = `${exitUrl}${window.location.hash}`);
    }
};
